<template>
  <a
    v-bind:href="downloadLink"
    target="_blank"
    class="recipeDownload"
  >
    <div class="recipeDownload__title">
      {{ $t('programDetailRecipes.downloadBannerTitle', { period }) }}
    </div>

    <font-awesome-icon
      icon="cloud-arrow-down"
      class="recipeDownload__linkIcon"
    />

    {{ $t('programDetailRecipes.downloadBannerLabel', { period }) }}
  </a>
</template>

<script>
export default {
  props: {
    downloadLink: {
      type: String,
      required: true,
    },

    period: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.recipeDownload {
  display: block;
  padding: 16px 16px 138px 16px;
  background-color: $color-beige;
  background-image: url("../../assets/program/voedingschema-download-mobile.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: contain;
  border-radius: 10px;

  @include desktop {
    padding: 42px 40px;
    background-position: 90% 50%;
    background-image: url("../../assets/program/voedingschema-download-desktop.png");
  }
}

.recipeDownload__title {
  @include heading-4;
  margin-bottom: $spacing-xs;
  color: $color-black;
}

.recipeDownload__linkIcon {
  margin: 0 8px 0 0;
}
</style>
