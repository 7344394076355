<template>
  <div class="programDetailRecipesPage">
    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <base-heading type="4">
              {{ $t('programDetailRecipes.highlightTitle') }}
            </base-heading>

            <mobile-slider>
              <template v-if="!currentPeriod.recipes">
                <mobile-slider-item
                  v-for="n in 3"
                  v-bind:key="n"
                  mobile-width="75%"
                  desktop-width="33.3%"
                >
                  <skeleton-loader-card />
                </mobile-slider-item>
              </template>

              <template v-else>
                <mobile-slider-item
                  v-for="recipe in currentPeriod.recipes"
                  v-bind:key="recipe.id"
                  mobile-width="75%"
                  desktop-width="33.3%"
                >
                  <recipe-card
                    v-bind:recipe="recipe"
                    v-bind:in-slider="true"
                  />
                </mobile-slider-item>
              </template>
            </mobile-slider>

            <recipe-download
              v-if="currentPeriod.nutrition_plan"
              v-bind:download-link="currentPeriod.nutrition_plan"
              v-bind:period="currentPeriodNumber"
              class="programDetailRecipesPage__downloadBanner"
            />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <recipes-page
      v-bind:title="$t('programDetailRecipes.title')"
      title-size="heading-4"
      v-bind:show-favorites-button="false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import MobileSlider from '@/components/MobileSlider';
import MobileSliderItem from '@/components/MobileSliderItem';
import BaseHeading from '@/components/BaseHeading';
import RecipesPage from '@/pages/recipes/RecipesPage';
import SkeletonLoaderCard from '@/components/SkeletonLoaderCard';
import RecipeCard from '@/components/recipes/RecipeCard';
import RecipeDownload from '@/components/recipes/RecipeDownload';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    MobileSlider,
    MobileSliderItem,
    SkeletonLoaderCard,
    RecipeCard,
    RecipeDownload,
    RecipesPage,
    BaseHeading,
  },

  computed: {
    ...mapGetters('period', ['currentPeriod', 'currentPeriodNumber']),
  },
};
</script>

<style lang="scss" scoped>
.programDetailRecipesPage__downloadBanner {
  display: block;
  margin: 2rem 0;
}
</style>
